<template>
  <main>
    <div class="row rest">
      <div class="rest aside-bg"></div>
      <GetStartedSnb class="left-container" />
      <router-view class="right-container" />
      <div class="rest"></div>
    </div>
  </main>
</template>

<script>
import GetStartedSnb from "./GetStartedSnb.vue";

export default {
  components: { GetStartedSnb },
};
</script>

<style></style>
