<template>
  <nav class="snb aside-bg">
    <div class="menu">
      <div class="snb-title">get Started</div>
      <ul class="snb-item">
        <li>
          <router-link to="/get-started/configuration">개발 환경 준비</router-link>
        </li>
        <li>
          <router-link to="/get-started/example">TV 화면 캡쳐 예제</router-link>
        </li>
        <li>
          <router-link to="/get-started/example2">TV 화면 캡쳐(Rekognition)</router-link>
        </li>
        <li>
          <router-link to="/get-started/example3">ThinQ 가전 제어 예제</router-link>
        </li>
        <li>
          <router-link to="/get-started/example4">음성</router-link>
        </li>
        <li>
          <router-link to="/get-started/example5">기타 예제</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {};
</script>

<style></style>
